import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from 'react';
import { redirect } from 'redux-first-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import every from 'lodash/every';
import * as yup from 'yup';
import DOMPurify from 'dompurify';
import * as Sentry from '@sentry/react';

import {
  deleteCampaignLogo,
  updateCampaign,
  updateCampaignProduct,
  getCampaign
} from '../../../actions/campaign-builder';

import {
  getUserProfile,
  updateUserProfile
} from '../../../actions/user-profile';

import slugify from '../../../helpers/slugify';
import getInitials from '../../../helpers/getInitials';
import { sortColours } from '../../../helpers/campaignBuilder';
import useTouchDetect from '../../../helpers/hooks/useTouchDetect';

import Alert from '../../../components/atoms/Alert';
import Avatar from '../../../components/atoms/Avatar';
import { ButtonNew as Button } from '../../../components/atoms/Button';
import Heading from '../../../components/atoms/Heading';
import Icon from '../../../components/atoms/Icon';
import IconButton from '../../../components/atoms/IconButton';
import Image from '../../../components/atoms/Image';
import Paragraph from '../../../components/atoms/Paragraph';
import Spacing from '../../../components/atoms/Spacing';
import { TooltipNew as Tooltip } from '../../../components/atoms/Tooltip';
import {
  Form,
  DefaultField as Field,
  Field as AltField
} from '../../../components/atoms/Form';

import Price from '../../../components/atoms/Price';

import Loading from '../../../components/utils/Loading';

import LogoUploaderModal from './Modals/LogoUploader';

import { default as Editor } from './Editor/Tiptap';

import generalStyles from './style/general.module.css';

import arrowLeftRightIcon from '../../../img/sprites/arrow-left-right-line.svg';
import imageIcon from '../../../img/sprites/image-line.svg';
import questionMarkIcon from '../../../img/sprites/question-mark-circle.svg';
import crossIcon from '../../../img/sprites/cross.svg';
import arrowRightIcon from '../../../img/sprites/arrow-right.svg';

const FRONT_AREA_SLUG = 'front';
const BACK_AREA_SLUG = 'back';

const DEFAULT_COLOUR_CODE = 'ffffff';
const PREVIEW_IMAGE_SCALE = 1.2;

const displayNameTooltipContent = (
  <Paragraph size="xxs">
    This can be your real name or your creator name. You can change this later
    in your profile settings.
  </Paragraph>
);

const campaignSlugTooltipContent = (
  <Paragraph size="xxs">Choose the URL of your campaign.</Paragraph>
);

const editorTooltipContent = (
  <Paragraph size="xxs">
    Add text, images, videos and embed social links to tell your story.
  </Paragraph>
);

const InfoFormSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .max(35),
  slug: yup
    .string()
    .required()
    .max(150)
    .matches(/^[aA-zZ0-9-]+$/, 'Spaces and special characters are not allowed'),
  description: yup.string().max(150)
});

const ProfileFormSchema = yup.object().shape({
  profileName: yup
    .string()
    .required()
    .max(100)
});

const PageEditor = () => {
  const dispatch = useDispatch();
  const {
    status,
    campaignId,
    name,
    slug,
    description,
    campaignProducts,
    logoUrl,
    url: campaignUrl,
    artworkPositions,
    currencyIso,
    content,
    defaultSide
  } = useSelector((state) => state.campaignBuilder, shallowEqual);
  const profileName = useSelector((state) => state.profile.profileName) ?? '';
  const [featuredCampaignProduct, setFeaturedCampaignProduct] = useState({});
  const [productsError, setProductsError] = useState(null);
  const [logoError, setLogoError] = useState(null);
  const [infoError, setInfoError] = useState(null);
  const [contentError, setContentError] = useState(null);
  const [profileError, setProfileError] = useState(null);
  const [tempBgColour, setTempBgColour] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [artworkLoaded, setArtworkLoaded] = useState(false);
  const [logoUploaderOpen, setLogoUploaderOpen] = useState(false);
  const { isTouch } = useTouchDetect();

  const infoFormRef = useRef(null);
  const profileFormRef = useRef(null);

  useEffect(() => {
    dispatch(getCampaign());
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(campaignProducts)) return;

    setFeaturedCampaignProduct(
      campaignProducts.find((campaignProduct) => campaignProduct.featured) || {}
    );
  }, [campaignProducts]);

  const featuredProductArea =
    featuredCampaignProduct?.product?.areas?.find(
      (area) => area.slug === defaultSide
    ) || {};

  const featuredArtworkPosition =
    artworkPositions.find((pos) => pos.area === defaultSide) || {};

  const artworkUrl = featuredArtworkPosition?.artwork?.url;

  const featuredCampaignProductColours = featuredCampaignProduct
    ? sortColours(featuredCampaignProduct.colours)
    : [];
  const allCampaignProductColours = campaignProducts.flatMap((p) => p.colours);
  const initialFeaturedColour = featuredCampaignProduct?.colourFeatured?.code;

  const handlePreview = async (e) => {
    e.preventDefault();

    if (status !== 'resolved') return;

    await infoFormRef.current?.submitForm();
    await profileFormRef.current?.submitForm();

    const isPreviewAllowed = every([name, slug, profileName]);

    if (isPreviewAllowed) {
      const key = campaignUrl.match(/key=([^&]*)/)[1];

      dispatch(
        redirect({
          type: 'CAMPAIGN_OR_STORE',
          payload: { slug_2: slug },
          query: { key }
        })
      );
    }
  };

  const handleFeaturedProductChange = async (product) => {
    setProductsError(null);

    try {
      await dispatch(updateCampaignProduct({ ...product, featured: true }));
    } catch (err) {
      setProductsError(
        err?.message || 'Unable to update products, please try again later.'
      );

      Sentry.captureException(err);
    }
  };

  const handleFeaturedAreaChange = async () => {
    setProductsError(null);

    try {
      const selectedAreaType =
        defaultSide === FRONT_AREA_SLUG ? BACK_AREA_SLUG : FRONT_AREA_SLUG;

      await dispatch(updateCampaign({ defaultSide: selectedAreaType }));
    } catch (err) {
      setProductsError(
        err?.message || 'Unable to update products, please try again.'
      );
    }
  };

  const handleCampaignLogoDelete = async () => {
    setLogoError(null);

    if (logoUrl) {
      try {
        await dispatch(deleteCampaignLogo());
      } catch (err) {
        setLogoError(
          err?.message || 'Unable to delete logo, please try again later.'
        );

        Sentry.captureException(err);
      }
    }
  };

  const handleCampaignInfoChange = useCallback(
    async (values) => {
      setInfoError(null);

      try {
        const { name, slug, description } = values;
        const cleanDescription = DOMPurify.sanitize(description);

        await dispatch(
          updateCampaign({ name, slug, description: cleanDescription })
        );
      } catch (err) {
        setInfoError(
          err?.message || 'Unable to update campaign, please try again later.'
        );

        Sentry.captureException(err);
      }
    },
    [dispatch]
  );

  const handleProfileNameChange = useCallback(
    async (values) => {
      setProfileError(null);

      try {
        const { profileName } = values;

        await dispatch(updateUserProfile({ name: profileName }));
      } catch (err) {
        setProfileError(
          err?.message || 'Unable to update profile, please try again later.'
        );

        Sentry.captureException(err);
      }
    },
    [dispatch]
  );

  const handleProductFeaturedColourSelect = useCallback(
    (values, { initialValues }) => {
      if (!isEqual(values, initialValues)) {
        setProductsError(null);

        const { featuredColour: featuredColourCode } = values;

        const featuredColour = featuredCampaignProduct.colours.find(
          (colour) => colour.code === featuredColourCode
        );

        try {
          dispatch(
            updateCampaignProduct({
              ...featuredCampaignProduct,
              colourFeatured: featuredColour
            })
          );
        } catch (err) {
          setProductsError(
            err?.message || 'Unable to update colours, please try again later.'
          );

          Sentry.captureException(err);
        }
      }
    },
    [featuredCampaignProduct, dispatch]
  );

  const handleCampaignContentChange = async (content) => {
    try {
      await dispatch(updateCampaign({ content }));
    } catch (err) {
      setContentError(
        err?.message || 'Unable to update content, please try again later.'
      );
    }
  };

  const debouncedProductFeaturedColourSelect = useMemo(
    () => debounce(handleProductFeaturedColourSelect, 2000),
    [handleProductFeaturedColourSelect]
  );

  const imagePlaceholderStyles = {
    position: 'relative',
    width: `${500 * PREVIEW_IMAGE_SCALE}px`,
    height: `${500 * PREVIEW_IMAGE_SCALE}px`
  };

  const productImageStyles = {
    width: `${500 * PREVIEW_IMAGE_SCALE}px`,
    height: `${500 * PREVIEW_IMAGE_SCALE}px`,
    backgroundColor: tempBgColour
      ? `#${tempBgColour}`
      : imageLoaded
      ? `#${featuredCampaignProduct.colourFeatured?.code}`
      : 'transparent',
    transition: 'background-color .15s linear'
  };

  const artworkAreaStyles = {
    position: 'absolute',
    width: featuredProductArea.width * PREVIEW_IMAGE_SCALE,
    height: featuredProductArea.height * PREVIEW_IMAGE_SCALE,
    top: featuredProductArea.posY * PREVIEW_IMAGE_SCALE,
    left: featuredProductArea.posX * PREVIEW_IMAGE_SCALE
  };

  const artworkStyles = {
    position: 'absolute',
    width: featuredArtworkPosition.width * PREVIEW_IMAGE_SCALE,
    height: featuredArtworkPosition.height * PREVIEW_IMAGE_SCALE,
    top: featuredArtworkPosition.y * PREVIEW_IMAGE_SCALE,
    left: featuredArtworkPosition.x * PREVIEW_IMAGE_SCALE,
    opacity: imageLoaded && artworkLoaded ? 1 : 0,
    transition: 'opacity .1s linear'
  };

  const logoStyles = {
    maxWidth: '175px',
    maxHeight: '50px',
    width: 'fit-content'
  };

  const isLoading = status === 'loading';
  const isUpdating = status === 'updating';

  const featuredSideMenuVisible = artworkPositions.length > 1;
  const featuredProductMenuVisible = campaignProducts.length > 1;
  const featuredColourMenuVisible = allCampaignProductColours.length > 1;

  if (isLoading || isEmpty(campaignProducts)) return <Loading />;

  return (
    <>
      <div className={generalStyles.preview}>
        <Button
          state={isUpdating || isLoading ? 'loading' : 'default'}
          onClick={handlePreview}
        >
          Preview
          <span className="ml-05">
            <Icon width={12} height={12} glyph={arrowRightIcon} />
          </span>
        </Button>
      </div>

      {/* Modals */}
      <LogoUploaderModal
        isOpen={logoUploaderOpen}
        onSubmit={() => setLogoUploaderOpen(false)}
        onClose={() => setLogoUploaderOpen(false)}
        campaignId={campaignId}
      />

      <div className={generalStyles.wrapper}>
        <div className={generalStyles.container}>
          <div className={generalStyles.grid}>
            <div>
              <div className={generalStyles.imageContainer}>
                <div style={imagePlaceholderStyles}>
                  {!isEmpty(featuredProductArea) && (
                    <>
                      <Image
                        src={featuredProductArea.greyUrl}
                        alt="Preview"
                        width="600"
                        height="600"
                        lazyLoad={false}
                        withPlaceholder={false}
                        style={productImageStyles}
                        onLoad={() => setImageLoaded(true)}
                      />

                      {artworkUrl ? (
                        <div style={artworkAreaStyles}>
                          <div className="relative w-full h-full">
                            <div style={artworkStyles}>
                              <Image
                                src={artworkUrl}
                                alt="Artwork"
                                lazyLoad={false}
                                withPlaceholder={false}
                                onLoad={() => setArtworkLoaded(true)}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>

                {featuredSideMenuVisible ||
                featuredProductMenuVisible ||
                featuredColourMenuVisible ? (
                  <div className="flex flex-col items-center gap-8">
                    <Heading size="xxs" color="primary-grey">
                      Featured product
                    </Heading>

                    {featuredSideMenuVisible ? (
                      <div className="block">
                        <Button size="small" onClick={handleFeaturedAreaChange}>
                          <span className="mr-05">
                            <Icon
                              glyph={arrowLeftRightIcon}
                              width={13}
                              height={13}
                            />
                          </span>
                          {defaultSide === FRONT_AREA_SLUG ? 'Front' : 'Back'}
                        </Button>
                      </div>
                    ) : null}

                    {featuredProductMenuVisible ? (
                      <div className="inline-flex flex-wrap justify-center gap-6">
                        {campaignProducts.map((campaignProduct) => {
                          const imageUrl = campaignProduct.product.areas.find(
                            (area) => area.slug === FRONT_AREA_SLUG
                          )?.greyUrl;

                          const activeBgColour =
                            campaignProduct.colourFeatured.code ||
                            DEFAULT_COLOUR_CODE;

                          const isFeatured = campaignProduct.featured;

                          return (
                            <Tooltip
                              id={`tooltip-${campaignProduct.campaignProductId}`}
                              content={
                                <div className="flex flex-col items-center gap-3">
                                  <Paragraph size="xxs">
                                    {campaignProduct.product.name}
                                  </Paragraph>
                                </div>
                              }
                              placement="bottom"
                              offset={7}
                              align="center"
                              key={campaignProduct.campaignProductId}
                            >
                              <div
                                className={classNames(generalStyles.product, {
                                  [generalStyles.featured]: isFeatured
                                })}
                                style={{ backgroundColor: '#f7f8fa' }}
                                onClick={
                                  isFeatured
                                    ? undefined
                                    : () =>
                                        handleFeaturedProductChange(
                                          campaignProduct
                                        )
                                }
                              >
                                <div className="relative">
                                  <Image
                                    src={imageUrl}
                                    alt="Preview"
                                    // className="absolute"
                                    width={70}
                                    height={70}
                                    withPlaceholder={false}
                                    lazyLoad={false}
                                    style={{
                                      width: 'fit-content',
                                      backgroundColor: `#${activeBgColour}`
                                    }}
                                  />
                                </div>
                              </div>
                            </Tooltip>
                          );
                        })}
                      </div>
                    ) : null}

                    {featuredColourMenuVisible ? (
                      <div>
                        <Form
                          onSubmit={() => null}
                          onChange={debouncedProductFeaturedColourSelect}
                          initialValues={{
                            featuredColour: initialFeaturedColour
                          }}
                          enableReinitialize={true}
                          render={({ handleSubmit }) => {
                            return (
                              <form onSubmit={handleSubmit}>
                                <div className="inline-flex gap-3">
                                  {featuredCampaignProductColours.map(
                                    (colour) => {
                                      return (
                                        <Tooltip
                                          id="tooltip-colour"
                                          hasListContent
                                          content={colour.name}
                                          placement="bottom"
                                          offset={5}
                                          align="start"
                                          alignOffset={-10}
                                          key={colour.colourId}
                                        >
                                          <div
                                            style={{ width: '30px' }}
                                            onMouseEnter={() =>
                                              setTempBgColour(colour.code)
                                            }
                                            onMouseLeave={() =>
                                              setTempBgColour(null)
                                            }
                                          >
                                            <AltField
                                              type="colourCircleRadio"
                                              id={colour.code}
                                              name="featuredColour"
                                              value={colour.code}
                                              hex={`#${colour.code}`}
                                              size="l"
                                            />
                                          </div>
                                        </Tooltip>
                                      );
                                    }
                                  )}
                                </div>
                              </form>
                            );
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {productsError && <Alert>{productsError}</Alert>}
              </div>
            </div>

            <div>
              <Form
                onSubmit={handleCampaignInfoChange}
                onChange={(values, form) => {
                  if (!form.initialValues.slug && values.name && !infoError) {
                    form.setFieldValue('slug', slugify(values.name));
                  }
                }}
                initialValues={{
                  name,
                  slug,
                  description
                }}
                validationSchema={InfoFormSchema}
                validateOnChange={false}
                validateOnSubmit={false}
                validateOnBlur={true}
                enableReinitialize={true}
                ref={infoFormRef}
                render={({
                  handleSubmit,
                  handleBlur,
                  validateForm,
                  values
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="flex flex-col gap-6">
                        {logoUrl ? (
                          <>
                            <div className={generalStyles.logo}>
                              <span
                                className={
                                  isTouch
                                    ? generalStyles.deleteButtonTouch
                                    : generalStyles.deleteButton
                                }
                              >
                                <IconButton
                                  type="button"
                                  glyph={crossIcon}
                                  size="tiny"
                                  kind="secondary"
                                  title="Remove"
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    return handleCampaignLogoDelete();
                                  }}
                                />
                              </span>
                              <div className="relative">
                                <div className="block">
                                  <Image
                                    src={logoUrl}
                                    alt="Logo"
                                    withPlaceholder={true}
                                    placeholderColor="transparent"
                                    lazyLoad={false}
                                    height={50}
                                    style={logoStyles}
                                  />
                                </div>
                              </div>
                            </div>

                            {logoError && <Alert>{logoError}</Alert>}
                          </>
                        ) : (
                          <div className="inline-flex items-center gap-6">
                            <Button
                              type="button"
                              kind="outline"
                              onClick={() => setLogoUploaderOpen(true)}
                            >
                              <span className="mr-1">
                                <Icon
                                  glyph={imageIcon}
                                  width={16}
                                  height={16}
                                />
                              </span>
                              Add your logo
                            </Button>
                          </div>
                        )}
                        <div>
                          <Field
                            type="text"
                            name="name"
                            maxLength={35}
                            placeholder="Campaign name"
                            scrollToError={false}
                            onBlur={async (e) => {
                              handleBlur(e);
                              const errors = await validateForm();

                              if (isEmpty(errors)) {
                                handleSubmit();
                              }
                            }}
                          />
                        </div>
                        <div className="inline-flex items-start gap-6">
                          <div className="block w-full">
                            <Field
                              type="text"
                              name="slug"
                              maxLength={150}
                              placeholder="Campaign slug"
                              prefixedText="everpress.com/"
                              scrollToError={false}
                              onBlur={async (e) => {
                                handleBlur(e);
                                const errors = await validateForm();

                                if (isEmpty(errors)) {
                                  handleSubmit();
                                }
                              }}
                            />
                          </div>
                          <div className="mt-15 block">
                            <Tooltip
                              id="tooltip-campaign-slug"
                              content={campaignSlugTooltipContent}
                              placement="bottom"
                              offset={7}
                              align="center"
                              width={245}
                            >
                              <Icon
                                className="mt-px opacity-25 hover:opacity-100 transition-opacity duration-100"
                                glyph={questionMarkIcon}
                                width={14}
                                height={14}
                              />
                            </Tooltip>
                          </div>
                        </div>
                        <div>
                          <Spacing size="05" position="t">
                            <Price
                              value={featuredCampaignProduct.price}
                              currency={currencyIso}
                              className="text-lg"
                            />
                          </Spacing>
                        </div>
                        <div>
                          <Spacing size={3} position="t">
                            <hr className="border-grey-lighter" />
                          </Spacing>

                          <Spacing size={3} position="t">
                            <Field
                              type="textarea"
                              name="description"
                              maxLength={150}
                              placeholder={
                                values.description
                                  ? 'Campaign description'
                                  : 'Write a short description about your campaign and design. This will be what everyone reads first so makes sure it’s clear and concise. There is a 150 character limit.'
                              }
                              scrollToError={false}
                              onBlur={async (e) => {
                                handleBlur(e);
                                const errors = await validateForm();

                                if (isEmpty(errors)) {
                                  handleSubmit();
                                }
                              }}
                            />
                          </Spacing>
                        </div>

                        <div className="mt-px">
                          <Spacing size={15} position="t">
                            <hr className="border-grey-lighter" />
                          </Spacing>
                        </div>

                        {infoError && <Alert>{infoError}</Alert>}
                      </div>
                    </form>
                  );
                }}
              />

              <Form
                onSubmit={handleProfileNameChange}
                initialValues={{
                  profileName
                }}
                validationSchema={ProfileFormSchema}
                validateOnChange={false}
                validateOnSubmit={false}
                validateOnBlur={true}
                enableReinitialize
                ref={profileFormRef}
                render={({
                  handleSubmit,
                  handleBlur,
                  validateForm,
                  values
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="mt-px">
                        <Spacing size={3} position="t">
                          <div className="inline-flex items-center gap-6 w-full">
                            <div className="block">
                              <Avatar
                                initials={getInitials(values.profileName || '')}
                              />
                            </div>
                            <Field
                              type="text"
                              name="profileName"
                              placeholder="Creator name"
                              maxLength={100}
                              scrollToError={false}
                              onBlur={async (e) => {
                                handleBlur(e);
                                const errors = await validateForm();

                                if (isEmpty(errors)) {
                                  handleSubmit();
                                }
                              }}
                            />
                            <Tooltip
                              id="tooltip-campaign-logo"
                              content={displayNameTooltipContent}
                              placement="bottom"
                              offset={7}
                              align="center"
                              width={280}
                            >
                              <Icon
                                className="mb-px opacity-25 hover:opacity-100 transition-opacity duration-100"
                                glyph={questionMarkIcon}
                                width={14}
                                height={14}
                              />
                            </Tooltip>
                          </div>
                        </Spacing>
                      </div>

                      {profileError && (
                        <Spacing size={15} position="t">
                          <Alert>{profileError}</Alert>
                        </Spacing>
                      )}
                    </form>
                  );
                }}
              />
            </div>

            <div className={generalStyles.infoEditor}>
              <div className="inline-flex justify-center items-center gap-6 w-full">
                <Heading size="m" tag="h2">
                  Additional content
                </Heading>
                <Tooltip
                  id="tooltip-campaign-logo"
                  content={editorTooltipContent}
                  placement="bottom"
                  offset={7}
                  align="center"
                  width={320}
                >
                  <Icon
                    className="mb-px opacity-25 hover:opacity-100 transition-opacity duration-100"
                    glyph={questionMarkIcon}
                    width={14}
                    height={14}
                  />
                </Tooltip>
              </div>

              <div className="mt-4">
                <Editor
                  content={content}
                  contentError={contentError}
                  onSave={handleCampaignContentChange}
                />
              </div>

              <div className="mt-8" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageEditor;
