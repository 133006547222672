/**
 *
 * PLEASE READ THE README.MD FILE BEFORE STARTING TO CODE!
 *
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactModal from 'react-modal';
import isEqual from 'react-fast-compare';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import difference from 'lodash/difference';
import keys from 'lodash/keys';
import partial from 'lodash/partial';
import flow from 'lodash/flow';
import unionWith from 'lodash/fp/unionWith';

import './index.css';

import { getActiveCampaigns } from './helpers/campaign';

import * as userActions from './actions/user';
import * as currenciesActions from './actions/currencies';
import * as trackingActions from './actions/tracking';
import * as shopActions from './actions/shop';

import Api from './services/api';

import * as persistentState from './services/local-storage';
import detectLocale from './services/detectLocale';
import routeValidator from './services/routeValidator';
import './services/sentry';
import { init as initFbPixel } from './services/facebookPixel';
import amplitude from './services/amplitude';

import { createClient } from '@prismicio/client';
import { PrismicProvider } from '@prismicio/react';

import config from './config';
import configureStore from './configureStore';

import App from './components/molecules/App';

// Instance Api class as a singleton
const baseApi = new Api({ baseUri: config.baseApiUri });

// Instance S3 file uploader api as a singleton
const fileUploaderApi = new Api({ baseUri: config.fileUploaderApiUri });

// Instance Wordpress api as a singleton
const wpApi = new Api({ baseUri: config.wordpressApiUri });

// Instance Prismic client as a singleton
const prismicClient = createClient(config.prismicRepoName, {
  accessToken: config.prismicAccessToken
});

// Init Redux store
const { store, initialDispatch } = configureStore({
  middlewareExtraArguments: {
    baseApi,
    fileUploaderApi,
    wpApi,
    prismicClient
  },
  onBeforeLocationChange: (dispatch, getState, { action }) =>
    routeValidator(dispatch, getState, action)
});

const initialState = store.getState();
let currentUser = initialState.user;
let currentCart = initialState.cart;
let currentTracking = initialState.tracking;
let currentOrder = initialState.checkout;
let currentCampaigns = initialState.campaigns;
let currentCampaignBuilder = initialState.campaignBuilder;

baseApi.setToken(currentUser.token);
baseApi.setParams(currentTracking.utmParams);
fileUploaderApi.setToken(currentUser.token);

// Detect default user currency
const geoIpApi = new Api({ baseUri: config.geoIpApiUri });

store.dispatch(shopActions.getShopCollections());

if (!currentUser.currency || !currentUser.country) {
  Promise.all([
    store.dispatch(currenciesActions.getCurrencies()),
    geoIpApi.get().catch((error) => null) // If error, we just fallback to browser detection
  ])
    .then(([currencyAction, geoIpResponse]) => {
      const defaultLocale = detectLocale(currencyAction.value, geoIpResponse);
      store.dispatch(userActions.setUserLocale(defaultLocale));
    })
    .catch((error) => {
      console.log(error);
    });
} else {
  store.dispatch(currenciesActions.getCurrencies());
}

// Listen to store changes
// If this keeps growing, think about using something like redux-persist instead
store.subscribe(() => {
  // If a persistent data changes, store it in the local storage.
  const {
    location,
    user,
    cart,
    tracking,
    checkout,
    campaigns,
    campaignBuilder
  } = store.getState();

  // If user state changes
  if (!isEqual(currentUser, user)) {
    if (user.token !== currentUser.token) {
      baseApi.setToken(user.token);
      fileUploaderApi.setToken(user.token);
      // User level changed, so we re-validate the route
      routeValidator(store.dispatch, store.getState, location);
    }

    // User logged out, reset builder campaignId
    if (!user.token) {
      persistentState.set(config.localStorageCampaignIdKey, null);
    }

    persistentState.set(
      config.localStorageAuthKey,
      pick(user, [
        'token',
        'refreshToken',
        'refreshTokenExpiration',
        'cartToken',
        'country',
        'currency',
        'rutter',
        'rutterConnections',
        'offPlatformAllowed',
        'impersonatedUser'
      ])
    );

    currentUser = user;

    // User impersonation
    if (user.token) {
      const { extendedRoles = [] } = user;
      const isAdmin = extendedRoles.includes(config.roles.ADMIN);

      if (isAdmin) {
        if (user.impersonatedUser) {
          baseApi.setParams({ _switch_user: user.impersonatedUser });

          // User level changed, so we re-validate the route
          routeValidator(store.dispatch, store.getState, location);
        } else {
          baseApi.setParams({});
        }
      }
    }
  }

  // If cart state changes
  if (
    currentCart.currency !== cart.currency ||
    currentCart.token !== cart.token ||
    !isEqual(currentCart.items, cart.items)
  ) {
    currentCart = cart;
    persistentState.set(
      config.localStorageCartKey,
      omit(cart, ['recommended', 'status'])
    );
  }

  // If tracking state changes
  if (!isEqual(currentTracking, tracking)) {
    currentTracking = tracking;
    persistentState.set(config.localStorageTrackingKey, tracking);
    baseApi.setParams(tracking.utmParams);
  }

  // If order state changes
  if (!isEqual(currentOrder, checkout)) {
    currentOrder = checkout;
    persistentState.set(config.localStorageOrderKey, checkout);
  }

  if (!isEqual(currentCampaigns.byId, campaigns.byId)) {
    try {
      // Helper we use to limit the number of campaigns to be saved in ls
      const truncateCampaigns = (campaigns) => {
        let newCampaigns = [...campaigns];
        newCampaigns.length = Math.min(
          campaigns.length,
          config.shop.recentlyViewedCampaigns.limit
        );

        return newCampaigns;
      };

      const getCampaignObj = (item) => campaigns.byId[item];
      const byUserId = (item) => item.user?.userId !== user.userId;

      // Get added campaigns IDs
      const nextCampaignIds = difference(
        keys(campaigns.byId),
        keys(currentCampaigns.byId)
      );

      // Build an array of added campaigns excluding user's own campaigns
      const nextCampaigns = nextCampaignIds
        .map(getCampaignObj)
        .filter(byUserId);

      // Exclude ended campaigns
      const nextActiveCampaigns = getActiveCampaigns(nextCampaigns);

      // Partially applied helper we use to merge and dedupe campaigns
      const unionNextActiveCampaigns = partial(
        unionWith(isEqual),
        nextActiveCampaigns
      );

      // Final composite func to be invoked by the ls service
      const mergeNextActiveCampaigns = flow(
        unionNextActiveCampaigns,
        truncateCampaigns
      );

      currentCampaigns.byId = campaigns.byId;
      persistentState.transform(
        config.localStorageCampaignsKey,
        mergeNextActiveCampaigns
      );
    } catch (error) {
      console.log(error);
    }
  }

  if (!isEqual(currentCampaignBuilder.campaignId, campaignBuilder.campaignId)) {
    currentCampaignBuilder.campaignId = campaignBuilder.campaignId;

    if (!campaignBuilder.campaignId) {
      persistentState.set(config.localStorageCampaignIdKey, null);
    }

    if (campaignBuilder.campaignId) {
      persistentState.set(
        config.localStorageCampaignIdKey,
        campaignBuilder.campaignId
      );
    }

    // Reset new builder wizard whenever campaignId changes
    persistentState.set(config.localStorageBuilderKey, {
      step: 0,
      darkColoursToastDismissed: false
    });
  }

  if (
    !isEqual(
      currentCampaignBuilder.darkColoursToastDismissed,
      campaignBuilder.darkColoursToastDismissed
    )
  ) {
    currentCampaignBuilder.darkColoursToastDismissed =
      campaignBuilder.darkColoursToastDismissed;

    const lsState = persistentState.get(config.localStorageBuilderKey);

    persistentState.set(config.localStorageBuilderKey, {
      ...lsState,
      darkColoursToastDismissed: campaignBuilder.darkColoursToastDismissed
    });
  }
});

// Tell to ReactModal that we are using #root as root element
ReactModal.setAppElement('#root');

// now app is ready to receive the first routing
initialDispatch();

// Save UTM params to LS
// This must be after `initialDispatch()` for location.query to be populated
const utmParams = pick(store.getState().location.query, [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content'
]);
store.dispatch(trackingActions.setUtmParams(utmParams));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <PrismicProvider client={prismicClient}>
    <Provider store={store}>
      <App />
    </Provider>
  </PrismicProvider>,
  // document.getElementById('root')
);

// Init marketing tracking tools
if (process.env.NODE_ENV === 'production') {
  initFbPixel(config.facebookPixel);
}
amplitude();
store.dispatch(trackingActions.setInitialLoad());
if (currentUser.token) {
  store.dispatch(userActions.setUserRoles());
}

// Disable browser auto scroll restoration
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}
