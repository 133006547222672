const config = {
  appTitle: 'Everpress',
  appUrl: process.env.REACT_APP_BASE_URI,
  adminUrl: process.env.REACT_APP_DOMAIN_ADMIN,
  appEnv: process.env.REACT_APP_ENV,
  facebookPixel: process.env.REACT_APP_FACEBOOK_PIXEL,
  amplitudeKey: process.env.REACT_APP_AMPLITUDE_KEY,
  sentryKey: process.env.REACT_APP_SENTRY_DSN,
  allowImpersonation: process.env.REACT_APP_ALLOW_IMPERSONATION,
  baseApiUri: '/api',
  fileUploaderApiUri: '/_uploader',
  wordpressApiUri: 'https://everpress.com/blog/wp-json/wp/v2',
  prismicRepoName: 'everpress',
  prismicAccessToken:
    'MC5ZX2Q5NUJJQUFDd0EtS2l2.77-977-9dVTvv73vv73vv73vv71G77-9bu-_vWh777-9LEUj77-9DO-_ve-_vXXvv73vv70XISnvv73vv73vv73vv70',
  geoIpApiUri:
    'https://api.ipdata.co?api-key=9e0cf93e2f9f085156d89842439c6ee10ca4fd5a528592a52b436dc5',
  zendeskKey: '17be1950-ddf9-46f8-9980-9e73487648ba',
  iframelyApiKey: 'd54a647ea3f2dcd4914223',
  loqate: {
    apiKey: 'JY59-XJ97-DG19-XR56',
    css: 'https://services.postcodeanywhere.co.uk/css/address-3.91.css',
    script: 'https://services.postcodeanywhere.co.uk/js/address-3.91.js'
  },
  trustpilot: {
    templateId: '5419b6a8b0d04a076446a9ad',
    businessUnitId: '564467770000ff0005857135'
  },
  isAppLogging: false,
  localStorageAuthKey: 'USER_SESSION',
  localStorageCartKey: 'CART_SESSION',
  localStorageOrderKey: 'ORDER_SESSION',
  localStorageTrackingKey: 'TRACKING_SESSION',
  localStorageConciergeKey: 'CONCIERGE_SESSION',
  localStorageCampaignsKey: 'CAMPAIGNS_SESSION',
  localStorageCampaignIdKey: 'campaignId', // Match old campaign builder key naming
  localStorageBuilderKey: 'BUILDER_SESSION',
  localStorageWelcomeOfferKey: 'WELCOME_OFFER',
  localStorageCookiesKey:
    'metomic-consented-pol:55dfc8bb-cb54-4804-b9c3-46d8b6d32867',
  roles: {
    VISITOR: 'visitor',
    MEMBER: 'member',
    BUYER: 'buyer',
    CREATOR: 'creator',
    ADMIN: 'admin',
    ADMIN_SUPER: 'admin_super',
    INTEGRATOR: 'integrator'
  },
  salesChannels: {
    everpress: 'everpress_channel',
    utm: 'utm',
    creator: 'creator'
  },
  utmParams: {
    marketing: {
      utm_term: 'epchannel'
    }
  },
  defaultLocale: 'en-GB',
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
  paypalId: process.env.REACT_APP_PAYPAL_ID,
  optimizeExperiment: {},
  optimizeProjectId: process.env.REACT_APP_GOOGLE_OPTIMIZE_PROJECT_ID,
  socials: {
    instagram: 'https://www.instagram.com/everpresshq',
    twitter: 'https://twitter.com/everpresshq'
  },
  campaignBuilder: {
    plateToBoxRatio: 0.2
  },
  dashboard: {
    orders: {
      ordersPerPage: 5
    },
    campaigns: {
      campaignsPerPage: 10,
      tabs: [
        {
          title: 'Live',
          mobileTitle: 'Live',
          id: 'live',
          sort: 'launchedAt'
        },
        {
          title: 'Pending',
          mobileTitle: 'Pending',
          id: 'pending_payment',
          sort: 'launchedAt',
          isOffPlatformAllowed: true
        },
        {
          title: 'Print',
          mobileTitle: 'Print',
          id: 'print',
          sort: 'launchedAt'
        },
        {
          title: 'Previous',
          mobileTitle: 'Previous',
          id: 'previous',
          sort: 'endAt'
        },
        {
          title: 'Draft',
          mobileTitle: 'Draft',
          id: 'draft',
          sort: 'createdAt'
        }
      ]
    },
    follow: {
      followsPerPage: 10
    },
    summary: {
      dateRange: 30
    }
  },
  fiftyFiftyShop: {
    defaultParams: { tag: '50-50', sort: 'sales', direction: 'DESC' }
  },
  shop: {
    campaigns: {
      limit: 45
    },
    recentlyViewedCampaigns: {
      limit: 12
    },
    defaultTag: {
      value: 'the-collection',
      label: 'Shop all',
      description: 'A constantly updated collection of our latest available designs – with new drops daily.'
    },
    garmentPageTag: {
      value: 'products',
      label: 'Products'
    },
    podTag: {
      value: 'ships-instantly',
      label: 'Fast shipping',
      description: 'Need something fast? We print these to order. No waiting for a campaign to end. And still no waste.'
    },
    seasonalTags: [
      'gift-ideas', 'holiday-collection', 'home-and-life-accessories'
    ],
    sortings: [
      {
        value: 'launched',
        label: 'Latest designs',
        direction: 'DESC',
        default: true
      },
      {
        value: 'sales',
        label: 'Most popular',
        direction: 'DESC'
      },
      {
        value: 'end',
        label: 'Ending soon',
        direction: 'ASC'
      }
    ],
    filters: {
      productCategories: [
        {
          value: 't-shirts',
          label: 'T-shirts',
          enabled: true
        },
        {
          value: 'long-sleeve',
          label: 'Long sleeve T-shirts',
          enabled: true
        },
        {
          value: 'sweatshirts',
          label: 'Sweatshirts',
          enabled: true
        },
        {
          value: 'hoodies',
          label: 'Hoodies',
          enabled: true
        },
        {
          value: 'caps',
          label: 'Caps',
          enabled: true
        },
        {
          value: 'beanies',
          label: 'Beanies',
          enabled: true
        },
        {
          value: 'tea-towels',
          label: 'Tea Towels',
          enabled: true
        },
        {
          value: 'bags',
          label: 'Bags',
          enabled: true
        },
        {
          value: 'crops-vests',
          label: 'Crops & Vests',
          enabled: true
        },
        {
          value: 'lifestyle',
          label: 'Lifestyle',
          enabled: true
        }
      ],
      productFits: [
        {
          value: 'regular',
          label: 'Regular',
          enabled: true
        },
        {
          value: 'relaxed',
          label: 'Relaxed',
          enabled: true
        },
        {
          value: 'oversized',
          label: 'Oversized',
          enabled: true
        },
        {
          value: 'fitted',
          label: 'Fitted',
          enabled: true
        }
      ],
      productPrintPositions: [
        {
          value: 'front',
          label: 'Front print',
          enabled: true
        },
        {
          value: 'back',
          label: 'Back print',
          enabled: true
        }
      ],
      productSizes: [
        {
          value: 'XS',
          label: 'XS',
          enabled: true
        },
        {
          value: 'S',
          label: 'S',
          enabled: true
        },
        {
          value: 'M',
          label: 'M',
          enabled: true
        },
        {
          value: 'L',
          label: 'L',
          enabled: true
        },
        {
          value: 'XL',
          label: 'XL',
          enabled: true
        },
        {
          value: '2XL',
          label: '2XL',
          enabled: true
        },
        {
          value: '3XL',
          label: '3XL',
          enabled: true
        },
        {
          value: '4XL',
          label: '4XL',
          enabled: true
        },
        {
          value: '5XL',
          label: '5XL',
          enabled: true
        }
      ],
      productColours: [
        {
          value: 'black',
          label: 'Black',
          hex: '#000000',
          enabled: true
        },
        {
          value: 'white',
          label: 'White',
          hex: '#ffffff',
          enabled: true
        },
        {
          value: 'grey',
          label: 'Grey',
          hex: '#999999',
          enabled: true
        },
        {
          value: 'blue',
          label: 'Blue',
          hex: '#324BC6',
          enabled: true
        },
        {
          value: 'red',
          label: 'Red',
          hex: '#D52E2E',
          enabled: true
        },
        {
          value: 'pink',
          label: 'Pink',
          hex: '#F9ABDB',
          enabled: true
        },
        {
          value: 'brown',
          label: 'Brown',
          hex: '#84574F',
          enabled: true
        },
        {
          value: 'yellow',
          label: 'Yellow',
          hex: '#FFEA22',
          enabled: true
        },
        {
          value: 'green',
          label: 'Green',
          hex: '#52A46B',
          enabled: true
        },
        {
          value: 'purple',
          label: 'Purple',
          hex: '#945CCF',
          enabled: true
        },
        {
          value: 'natural',
          label: 'Natural',
          hex: '#FCFDF6',
          enabled: true
        },
        {
          value: 'orange',
          label: 'Orange',
          hex: '#ffa500',
          enabled: true
        },
        {
          value: 'multi',
          label: 'Multi',
          hex: null,
          enabled: true
        }
      ]
    }
  },
  productShotsRatio: 0.8,
  header: {
    announcements: {
      supplier_disruption:
        'Our current range of available garments has been limited due to supplier disruption. Thank you for your patience.',
      garment_swap:
        'Everpress prints to order - due to Covid-19 supply issues, it may be necessary to swap your garment for the closest alternative.'
    }
  },
  stripeErrors: {
    payment_intent_authentication_failure:
      'An error happened while authorising your payment, please try again.',
    payment_intent_incompatible_payment_method:
      'Your card is not supported, please try a different card.',
    payment_intent_payment_attempt_failed:
      'An error happened while processing your order, please try again.',
    card_declined: 'Your card was declined, please try again.',
    default:
      'An error happened while processing your payment, please try again.'
  },
  stateSync: {
    cart: [
      'CREATE_CART_FULFILLED',
      'GET_CART_FULFILLED',
      'ADD_CART_ITEM_PENDING',
      'ADD_CART_ITEM_FULFILLED',
      'ADD_CART_ITEM_REJECTED',
      'EDIT_CART_ITEM_FULFILLED',
      'EDIT_CART_ITEM_REJECTED',
      'DELETE_CART_ITEM_PENDING',
      'DELETE_CART_ITEM_FULFILLED',
      'DELETE_CART_ITEM_REJECTED',
      'SET_CART_CURRENCY_FULFILLED',
      'SET_USER_CURRENCY',
      'CAMPAIGN_RECEIVED'
    ],
    user: [
      'SET_USER',
      'SET_USER_ROLES'
    ],
    campaignBuilder: [
      'UPDATE_BUILDER_CAMPAIGN_ID',
      'RESET_BUILDER',
      'CREATE_BUILDER_CAMPAIGN_PENDING',
      'CREATE_BUILDER_CAMPAIGN_FULFILLED',
      'CREATE_BUILDER_CAMPAIGN_REJECTED',
      'CREATE_BUILDER_ARTWORK_POSITIONS_FULFILLED',
      'GET_BUILDER_ARTWORK_POSITIONS_PENDING',
      'GET_BUILDER_ARTWORK_POSITIONS_FULFILLED',
      'GET_BUILDER_ARTWORK_POSITIONS_REJECTED',
      'GET_BUILDER_CATALOGUE_PRODUCTS_PENDING',
      'GET_BUILDER_CATALOGUE_PRODUCTS_FULFILLED',
      'GET_BUILDER_CATALOGUE_PRODUCTS_REJECTED',
      'GET_BUILDER_CAMPAIGN_PRODUCTS_PENDING',
      'GET_BUILDER_CAMPAIGN_PRODUCTS_FULFILLED',
      'GET_BUILDER_CAMPAIGN_PRODUCTS_REJECTED',
      'ADD_BUILDER_CAMPAIGN_PRODUCT_PENDING',
      'ADD_BUILDER_CAMPAIGN_PRODUCT_FULFILLED',
      'ADD_BUILDER_CAMPAIGN_PRODUCT_REJECTED',
      'DELETE_BUILDER_CAMPAIGN_PRODUCT_PENDING',
      'DELETE_BUILDER_CAMPAIGN_PRODUCT_FULFILLED',
      'DELETE_BUILDER_CAMPAIGN_PRODUCT_REJECTED',
      'UPDATE_BUILDER_CAMPAIGN_PRODUCT_PENDING',
      'UPDATE_BUILDER_CAMPAIGN_FULFILLED',
      'UPDATE_BUILDER_CAMPAIGN_PRODUCT_FULFILLED',
      'UPDATE_BUILDER_CAMPAIGN_PRODUCT_REJECTED',
      'UPLOAD_BUILDER_ARTWORK_PENDING',
      'UPLOAD_BUILDER_ARTWORK_FULFILLED',
      'UPLOAD_BUILDER_ARTWORK_REJECTED',
      'UPDATE_BUILDER_ARTWORK_FULFILLED',
      'UPDATE_BUILDER_PROFIT_ESTIMATE_FULFILLED',
      'DELETE_BUILDER_ARTWORK_POSITION_FULFILLED'
    ]
  },
  jwtRoutesToValidate: ['DASHBOARD', 'CHECKOUT'],
  jwtExpirationThreshold: 3600,
  refreshTokenExpirationThreshold: 300
};

export default config;
