import { NOT_FOUND, redirect } from 'redux-first-router';
import subDays from 'date-fns/sub_days';
import parse from 'date-fns/parse';
import { size } from 'lodash';

import config from './config';

import { hasExtendedRole, getUserPrimaryConnection } from './helpers/user';

import * as persistentState from './services/local-storage';

import * as campaignsActions from './actions/campaigns';
import * as pagesActions from './actions/pages';
import * as shopActions from './actions/shop';
import * as garmentsActions from './actions/garments';
import * as ordersActions from './actions/orders';
import * as userOrdersActions from './actions/user-orders';
import * as payoutsActions from './actions/payouts';
import * as cartActions from './actions/cart';
import * as checkoutActions from './actions/checkout';
import * as userActions from './actions/user';
import * as userProfileActions from './actions/user-profile';
import * as discoveryActions from './actions/discovery';
import * as campaignBuilderActions from './actions/campaign-builder';
import * as systemActions from './actions/system';

import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import RecoverPassword from './pages/RecoverPassword';
import CampaignBuilder from './pages/CampaignBuilder';
import Campaign from './pages/Campaign';
import Shop from './pages/Shop';
import GiftCards from './pages/GiftCards';
import Order from './pages/Order';
import Landing from './pages/Landing';
import Profile from './pages/Profile';
import Bandcamp from './pages/Bandcamp';
import Integrations from './pages/Integrations';
import Connect from './pages/Connect';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Contact from './pages/Contact';
import About from './pages/About';
import Terms from './pages/Terms';
import TermsBuyers from './pages/TermsBuyers';
import TermsSellers from './pages/TermsSellers';
import CookiePolicy from './pages/CookiePolicy';
import UsePolicy from './pages/UsePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Garments from './pages/Garments';
import HowItWorks from './pages/HowItWorks';
import CaseStudy from './pages/CaseStudy';
import CaseStudiesCategory from './pages/CaseStudiesCategory';
import CaseStudies from './pages/CaseStudies';
import BulkOrders from './pages/BulkOrders';
import EmailOptOut from './pages/EmailOptOut';
import LeadCapture from './pages/LeadCapture';
import LeadCaptureThanks from './pages/LeadCapture/Thanks';
import SubmitYourDesign from './pages/SubmitYourDesign';
import SubmitYourDesignThanks from './pages/SubmitYourDesign/Thanks';
import CreatorMobile from './pages/CreatorMobile';
import PriceCalculator from './pages/PriceCalculator';

import AboutStaging from './pages/About/staging';
import IntellectualPropertyPolicy from './pages/IntellectualPropertyPolicy';
import Favourites from './pages/Favourites';
import FiftyFifty from './pages/FiftyFifty';
import Sustainability from './pages/Sustainability';
import SaveOurSpaces from './pages/SaveOurSpaces';
import BCorp from './pages/BCorp';
import People from './pages/People';
import Careers from './pages/Careers';
import CreatorRewards from './pages/CreatorRewards';
import CreatorRewardsThanks from './pages/CreatorRewards/Thanks';

const { VISITOR, MEMBER, CREATOR, ADMIN, INTEGRATOR } = config.roles;

// Imported pages are bundled with the main JS file.

/**
 * 2 ways of defining the component for a route:
 * - To enable code-splitting on the route, define
 *   component name as string.
 *   WARNING: You CAN'T have the page in a sub-folder in this case
 *   (otherwise css file won't get imported).
 * - To not use code-splitting, import and reference
 *   the component directly.
 */

export default {
  HOME: {
    path: '/',
    component: Home,
    thunk: async (dispatch, getState) => {
      const categoryTags = getState().discovery.categoryTags;

      dispatch(
        pagesActions.getPage('homepage', {
          fetchLinks: [
            'ugc_rail.title',
            'ugc_rail.subtitle',
            'ugc_rail.campaigns',
            'feature_cards.title',
            'feature_cards.subtitle',
            'feature_cards.additional_title',
            'feature_cards.cards',
            'content_cards.title',
            'content_cards.subtitle',
            'content_cards.cards',
            'feature_blog_posts.title',
            'feature_blog_posts.subtitle',
            'feature_blog_posts.blog_posts'
          ]
        })
      );
      dispatch(discoveryActions.getFeaturedTags());
      dispatch(discoveryActions.getFeaturedProfiles());

      if (categoryTags.status !== 'resolved') {
        dispatch(discoveryActions.getCategoryTags());
      }
    }
  },
  CONTACT: {
    path: '/contact',
    component: Contact
  },
  ABOUT: {
    path: '/about',
    component: About
  },
  ABOUT_STAGING: {
    path: '/about-staging',
    component: AboutStaging,
    thunk: async (dispatch) => {
      try {
        await dispatch(pagesActions.getPageFromCollection('page', 'about'));
      } catch (err) {
        dispatch({ type: 'NOT_FOUND' });
      }
    }
  },
  TERMS: {
    path: '/terms-of-use',
    component: Terms
  },
  TERMSBUYERS: {
    path: '/terms-for-buyers',
    component: TermsBuyers
  },
  TERMS_SELLERS: {
    path: '/terms-for-sellers',
    component: TermsSellers
  },
  COOKIE_POLICY: {
    path: '/cookie-policy',
    component: CookiePolicy
  },
  USEPOLICY: {
    path: '/use-policy',
    component: UsePolicy
  },
  PRIVACY_POLICY: {
    path: '/privacy-policy',
    component: PrivacyPolicy
  },
  INTELLECTUAL_PROPERTY_POLICY: {
    path: '/intellectual-property-policy',
    component: IntellectualPropertyPolicy
  },
  LOGIN: {
    path: '/login',
    component: Login,
    role: VISITOR
  },
  REGISTER: {
    path: '/sign-up',
    component: Register,
    role: VISITOR
  },
  FORGOTTEN_PASSWORD: {
    path: '/forgotten-password',
    component: RecoverPassword,
    role: VISITOR
  },
  RESET_PASSWORD: {
    path: '/reset-password/:token',
    component: ResetPassword,
    role: VISITOR
  },
  GARMENTS: {
    path: '/garments/:category?/:garmentId?',
    component: Garments,
    thunk: (dispatch) => dispatch(garmentsActions.getGarments()),
    shouldUpdateScroll: (prevLocation) => {
      return prevLocation.type !== 'GARMENTS';
    }
  },
  HOW_IT_WORKS: {
    path: '/how-it-works/:category?',
    component: HowItWorks,
    shouldUpdateScroll: (prevLocation) => {
      return prevLocation.type !== 'HOW_IT_WORKS';
    }
  },
  CART: {
    path: '/cart/:token?',
    component: Cart,
    thunk: async (dispatch, getState) => {
      const urlToken = getState().location.payload.token;
      const lsCartToken = getState().cart.token;
      const prevLocation = getState().location.prev;

      if (urlToken) {
        return await dispatch(cartActions.getCart(urlToken));
      }

      if (lsCartToken && prevLocation.type !== 'CAMPAIGN_OR_STORE') {
        return await dispatch(cartActions.getCart(lsCartToken));
      }
    }
  },
  CHECKOUT: {
    path: '/checkout/:status?/:token?/:error?',
    component: Checkout,
    thunk: async (dispatch, getState) => {
      const urlToken =
        getState().location.payload.urlToken ||
        getState().location.payload.token;
      const lsCartToken = getState().cart.token;
      const lsUserToken = getState().user.token;

      // Fetch enabled payment providers
      await dispatch(systemActions.getSettings());

      if (lsUserToken && (urlToken || lsCartToken)) {
        await dispatch(userActions.getUser());
      }

      if (urlToken) {
        try {
          await dispatch(cartActions.getCart(urlToken));
          return dispatch(checkoutActions.getCheckoutDetails(urlToken));
        } catch {
          return dispatch(redirect({ type: 'HOME' }));
        }
      }

      if (lsCartToken) {
        return dispatch(cartActions.getCart(lsCartToken));
      }

      return dispatch(redirect({ type: 'HOME' }));
    }
  },
  ORDER: {
    path: '/order/confirmation/:token',
    component: Order,
    thunk: (dispatch, getState) => {
      const { token } = getState().location.payload;

      dispatch(
        userActions.getUserFollowees({
          limit: config.dashboard.follow.followsPerPage
        })
      );

      return dispatch(ordersActions.getOrder(token));
    }
  },
  CASE_STUDIES: {
    path: '/case-studies',
    component: CaseStudies,
    thunk: async (dispatch) =>
      dispatch(
        pagesActions.getPage('case_studies_landing', {
          fetchLinks: [
            'case_studies_category.title',
            'case_studies_category.subtitle',
            'case_studies_category.description',
            'case_studies_category.image'
          ]
        })
      )
  },
  CASE_STUDIES_CATEGORY: {
    path: '/case-studies/:slug',
    component: CaseStudiesCategory,
    thunk: async (dispatch, getState) => {
      const { slug } = getState().location.payload;
      const response = await dispatch(
        pagesActions.getPageFromCollection('case_studies_category', slug)
      );

      dispatch(
        pagesActions.getCollectionByCategory(
          'case_study',
          'category',
          response.value.id,
          {
            order: 'document.first_publication_date'
          }
        )
      );

      if (!response) {
        dispatch({ type: NOT_FOUND });
      }
    }
  },
  CASE_STUDY: {
    path: '/case-studies/:category/:slug',
    component: CaseStudy,
    thunk: async (dispatch, getState, { extra: { baseApi } }) => {
      const { slug } = getState().location.payload;
      return dispatch(
        pagesActions.getPageFromCollection('case_study', slug, {
          fetchLinks: [
            'case_study.title',
            'case_study.headline',
            'case_study.category',
            'case_study.image',
            'case_studies_category.title'
          ]
        })
      );
    }
  },
  LANDING: {
    path: '/landing/:slug',
    component: Landing,
    thunk: async (dispatch, getState) => {
      const { slug } = getState().location.payload;

      try {
        await dispatch(
          pagesActions.getPageFromCollection('landing', slug, {
            fetchLinks: [
              'case_study.title',
              'case_study.image',
              'case_study.category'
            ]
          })
        );
      } catch (err) {
        dispatch({ type: NOT_FOUND });
      }
    }
  },
  BULK_ORDER: {
    path: '/bulk-orders',
    component: BulkOrders
  },
  EMAIL_OPT_OUT: {
    path: '/order/:token/opt-out',
    component: EmailOptOut
  },
  SHOP: {
    path: '/shop/:tag?',
    component: Shop,
    thunk: async (dispatch, getState) => {
      const { location } = getState();
      const { tag } = location.payload;

      if (!tag || tag === config.shop.defaultTag.value) {
        dispatch(discoveryActions.getFeaturedCampaigns(tag));
      }

      // Make sure this is called on when user reach the page the 1st time
      if (location.prev && location.prev.type !== 'SHOP') {
        dispatch(pagesActions.getPage('shop'));
        dispatch(shopActions.getShopCollections());
      }
    },
    shouldUpdateScroll: (prevLocation) => {
      return (
        prevLocation.type !== 'SHOP' &&
        prevLocation.type !== 'CAMPAIGN_OR_STORE'
      );
    }
  },
  GIFT_CARDS: {
    path: '/gift-cards',
    component: GiftCards,
    thunk: async (dispatch) => {
      dispatch(shopActions.getGiftCards());
    }
  },
  LEAD_CAPTURE: {
    path: '/get-in-touch',
    component: LeadCapture
  },
  LEAD_CAPTURE_THANKS: {
    path: '/get-in-touch/thank-you',
    component: LeadCaptureThanks
  },
  SUBMIT_YOUR_DESIGN: {
    path: '/submit-your-design',
    component: SubmitYourDesign,
    thunk: async (dispatch) => {
      dispatch(pagesActions.getPage('submit_your_design'));
    }
  },
  SUBMIT_YOUR_DESIGN_THANKS: {
    path: '/submit-your-design/thank-you',
    component: SubmitYourDesignThanks,
    thunk: async (dispatch) => {
      dispatch(pagesActions.getPage('submit_your_design'));
    }
  },
  CREATOR_MOBILE: {
    path: '/create-mobile',
    component: CreatorMobile
  },
  PRICE_CALCULATOR: {
    path: '/price-calculator',
    component: PriceCalculator,
    thunk: (dispatch) => dispatch(garmentsActions.getGarments())
  },
  DASHBOARD_CAMPAIGNS: {
    path: `/dashboard/campaigns/:campaignId?`,
    component: 'Dashboard',
    role: MEMBER,
    thunk: async (dispatch, getState, { extra: { baseApi } }) => {
      const { details } = getState().user;
      const { campaignId } = getState().location.payload;
      await dispatch(userActions.setUserRoles());

      try {
        if (campaignId) {
          await dispatch(userActions.getUser());
          await dispatch(campaignsActions.getUserCampaign(campaignId));
          const campaign = getState().campaigns.byId[campaignId];
          const user = getState().user;
          const userPrimaryConnection = getUserPrimaryConnection(user);
          const isConnected = !!userPrimaryConnection;

          if (hasExtendedRole(CREATOR, getState()) && !campaign.draft) {
            await dispatch(
              campaignsActions.getCampaignsStatsBreakdown(
                parse(campaign.launchedAt),
                parse(campaign.blockAt),
                campaignId
              )
            );

            const countryBreakdownDispatch = dispatch(
              campaignsActions.getCampaignsCountryBreakdown(campaignId)
            );

            const productSalesDispatch = dispatch(
              campaignsActions.getCampaignsProductSalesBreakdown(campaignId)
            );

            const dispatches = [
              await countryBreakdownDispatch,
              await productSalesDispatch
            ];

            Promise.all(dispatches);

            if (isConnected) {
              await dispatch(
                campaignsActions.getCampaignPlatformBreakdown(campaignId)
              );
            }
          }
        } else {
          await Promise.all([
            dispatch(
              campaignsActions.getUserCampaigns({
                state: config.dashboard.campaigns.tabs[0].id,
                limit: config.dashboard.campaigns.campaignsPerPage,
                sort: config.dashboard.campaigns.tabs[0].sort
              })
            ),
            // i.e. also get user details if getState().user.details
            // contains more than `isLoading` and `email`
            size(details) <= 2 &&
              !details.isLoading &&
              dispatch(userActions.getUser()),
            // also get the profile data to display consistently across different uses
            !getState().profile?.profileName &&
              dispatch(userProfileActions.getUserProfile())
          ]);

          if (hasExtendedRole(CREATOR, getState())) {
            await dispatch(
              campaignsActions.getCampaignsStatsSummary(
                subDays(new Date(), config.dashboard.summary.dateRange),
                new Date()
              )
            );
          }
        }
      } catch (err) {
        dispatch({ type: NOT_FOUND });
      }
    }
  },
  DASHBOARD_PAYOUTS: {
    path: `/dashboard/payout-history`,
    component: 'Dashboard',
    role: MEMBER,
    thunk: async (dispatch) => {
      try {
        await dispatch(payoutsActions.getPayoutHistory());
        await dispatch(userActions.getUserCharges());
      } catch (e) {
        dispatch(redirect({ type: 'DASHBOARD' }));
      }
    }
  },
  DASHBOARD_ACCOUNT_SETTINGS: {
    path: `/dashboard/settings/:tab?`,
    component: 'Dashboard',
    role: MEMBER,
    thunk: async (dispatch, getState) => {
      const { user, location, profile, discovery } = getState();
      const { details, preferences, emailPreferences } = user;
      const { categoryTags } = discovery;
      const { tab } = location.payload;

      dispatch({
        type: 'PROFILE_PENDING'
      });

      // Dumb "cache" check to not refetch on every tab change
      if (
        (!tab || tab === 'account-info' || tab === 'creator-settings') &&
        details.isLoading === null
      ) {
        await dispatch(userActions.getUser());
      } else if (
        tab === 'notifications' &&
        (preferences.isLoading === null || emailPreferences.isLoading === null)
      ) {
        await dispatch(userActions.getUserPreferences());
        await dispatch(userActions.getEmailUserPreferences());
      } else if (tab === 'profile') {
        if (!profile.profileName && !profile.slug && !profile.bio) {
          await dispatch(userProfileActions.getUserProfile());
        }

        dispatch({
          type: 'PROFILE_READY',
          meta: {
            location: 'settings'
          }
        });
      } else if (tab === 'preferences') {
        await Promise.all([
          categoryTags.status !== 'resolved'
            ? dispatch(discoveryActions.getCategoryTags())
            : null,
          user.favouriteCategories.status === 'saved'
            ? dispatch({
                type: 'RESET_CATEGORY_PREFERENCES_FORM'
              })
            : dispatch(userActions.getUserFavouriteCategories())
        ]);
      }
    }
  },
  DASHBOARD_CREATOR_TOOLKIT: {
    path: `/dashboard/creator-toolkit`,
    component: 'Dashboard',
    role: MEMBER
  },
  DASHBOARD_ORDERS: {
    path: `/dashboard/orders/:orderId?`,
    component: 'Dashboard',
    role: MEMBER,
    thunk: async (dispatch, getState, { extra: { baseApi } }) => {
      const { orderId } = getState().location.payload;
      try {
        if (orderId) {
          await dispatch(userOrdersActions.getUserOrder(orderId));
        } else {
          await dispatch(
            userOrdersActions.getUserOrders({
              limit: config.dashboard.orders.ordersPerPage
            })
          );
        }
      } catch (err) {
        dispatch({ type: NOT_FOUND });
      }
    }
  },
  DASHBOARD_FOLLOWING: {
    path: `/dashboard/following`,
    component: 'Dashboard',
    role: MEMBER
  },
  DASHBOARD_CONNECT: {
    path: '/dashboard/connect',
    component: 'Dashboard',
    role: MEMBER,
    thunk: async (dispatch, getState, { extra: { baseApi } }) => {
      const { details } = getState().user;
      await dispatch(userActions.setUserRoles());

      try {
        await Promise.all([
          dispatch(
            campaignsActions.getUserCampaigns({
              state: config.dashboard.campaigns.tabs[0].id,
              limit: config.dashboard.campaigns.campaignsPerPage,
              sort: config.dashboard.campaigns.tabs[0].sort
            })
          ),
          // i.e. also get user details if getState().user.details
          // contains more than `isLoading` and `email`
          size(details) <= 2 &&
            !details.isLoading &&
            dispatch(userActions.getUser()),
          // also get the profile data to display consistently across different uses
          !getState().profile?.profileName &&
            dispatch(userProfileActions.getUserProfile())
        ]);

        if (hasExtendedRole(CREATOR, getState())) {
          await dispatch(
            campaignsActions.getCampaignsStatsSummary(
              subDays(new Date(), config.dashboard.summary.dateRange),
              new Date()
            )
          );
        }
      } catch (err) {
        dispatch({ type: NOT_FOUND });
      }
    }
  },
  DASHBOARD_CONNECT_PRODUCTS: {
    path: '/dashboard/connect-products/:connectionId?',
    component: 'Dashboard',
    role: MEMBER,
    thunk: async (dispatch, getState) => {
      const { connectionId } = getState().location.payload;
      if (connectionId) {
        try {
          await Promise.all([
            dispatch(userActions.getUser()),
            dispatch(
              campaignsActions.getUserCampaignsFull({
                state: 'live',
                sort: 'launchedAt'
              })
            )
          ]);
        } catch (err) {
          dispatch({ type: NOT_FOUND });
        }
      } else {
        dispatch(redirect({ type: 'DASHBOARD_CONNECTIONS' }));
      }
    }
  },
  DASHBOARD_CONNECTIONS: {
    path: '/dashboard/connections',
    component: 'Dashboard',
    role: MEMBER,
    thunk: async (dispatch) => {
      try {
        await dispatch(userActions.getUser());
      } catch (err) {
        dispatch({ type: NOT_FOUND });
      }
    }
  },
  DASHBOARD: {
    path: `/dashboard`,
    role: MEMBER,
    thunk: async (dispatch, getState) => {
      if (getState().user.token) {
        await dispatch(userActions.setUserRoles());
        dispatch(
          redirect({
            type: hasExtendedRole(INTEGRATOR, getState())
              ? 'DASHBOARD_CONNECT'
              : 'DASHBOARD_CAMPAIGNS'
          })
        );
        dispatch(
          userActions.getUserFollowees({
            limit: config.dashboard.follow.followsPerPage
          })
        );
      } else {
        dispatch(redirect({ type: 'LOGIN' }));
      }
    }
  },
  PROFILE: {
    path: '/profile/:slug',
    component: Profile,
    thunk: async (dispatch, getState, { extra: { baseApi } }) => {
      const currentState = getState();
      const { slug } = currentState.location.payload;
      try {
        let currentProfile;
        let userProfile = currentState.profile;

        dispatch({
          type: 'PROFILE_PENDING'
        });

        if (currentState.user.token && !userProfile.slug) {
          userProfile = await baseApi.get('/user/profile');
          await dispatch({
            type: 'GET_USER_PROFILE_FULFILLED',
            payload: userProfile
          });
        }

        const isOwner = userProfile.slug === slug;

        if (isOwner) {
          if (!currentState.profile.campaignsBySlug[slug])
            await dispatch(userProfileActions.getUserProfileCampaigns());

          currentProfile = userProfile;
        } else {
          if (!currentState.profile.bySlug[slug]) {
            currentProfile = await baseApi.get(`/profile/${slug}`);

            dispatch({
              type: 'GET_PROFILE_FULFILLED',
              payload: currentProfile
            });
          } else {
            currentProfile = currentState.profile.bySlug[slug];
          }
          await dispatch(userActions.getUserFollowees());
          if (!currentState.profile.campaignsBySlug[slug])
            await dispatch(userProfileActions.getProfileCampaigns(slug));
        }

        // // TODO: Profile name from backend changed from 'profileName' to 'name'
        // // This needs to be updated throughout the codebase. For now on initial page
        // // load, the key will be 'name' and on subsequent routes 'profileName'
        const profileName = currentProfile.profileName || currentProfile.name;

        dispatch({
          type: 'PROFILE_READY',
          meta: {
            location: 'profile',
            isOwner,
            name: profileName,
            bio: currentProfile.bio,
            slug: currentProfile.slug,
            ...(isOwner
              ? null
              : {
                  isFollowing: !!currentState.user.following.list.find(
                    (item) => item.userId === currentProfile.userId
                  )
                })
          }
        });
      } catch (err) {
        console.error(err);
        dispatch({ type: NOT_FOUND });
      }
    }
  },
  FAVOURITES: {
    path: '/favourites',
    component: Favourites,
    thunk: async (dispatch, getState) => {
      if (getState().user.token) {
        dispatch(userActions.getUserFavourites());
      }
    }
  },
  FIFTY_FIFTY: {
    path: '/50-50',
    component: FiftyFifty,
    thunk: async (dispatch, getState) => {
      dispatch(pagesActions.getPage('50-50'));
      dispatch(shopActions.getShopItems(config.fiftyFiftyShop.defaultParams));
    }
  },
  SUSTAINABILITY: {
    path: '/sustainability',
    component: Sustainability,
    thunk: async (dispatch, getState) => {
      dispatch(pagesActions.getPage('sustainability'));
    }
  },
  BCORP: {
    path: '/bcorp',
    component: BCorp,
    thunk: async (dispatch) => {
      try {
        await dispatch(pagesActions.getPageFromCollection('page', 'bcorp'));
      } catch (err) {
        dispatch({ type: 'NOT_FOUND' });
      }
    }
  },
  SAVE_OUR_SPACES: {
    path: '/save-our-spaces-open-call',
    component: SaveOurSpaces,
    thunk: async (dispatch) => {
      try {
        await dispatch(
          pagesActions.getPageFromCollection(
            'page',
            'save-our-spaces-open-call'
          )
        );
      } catch (err) {
        dispatch({ type: 'NOT_FOUND' });
      }
    }
  },
  PEOPLE: {
    path: '/people',
    component: People,
    thunk: async (dispatch, getState) => {
      dispatch(pagesActions.getPage('people'));
    }
  },
  CAREERS: {
    path: '/careers',
    component: Careers
  },
  CREATOR_REWARDS: {
    path: '/creator-rewards',
    component: CreatorRewards
  },
  CREATOR_REWARDS_THANKS: {
    path: '/creator-rewards/thank-you',
    component: CreatorRewardsThanks
  },
  BANDCAMP_SIGN_UP: {
    path: '/bandcamp',
    component: Bandcamp
  },
  INTEGRATIONS: {
    path: '/integrations',
    component: Integrations
  },
  CONNECT: {
    path: '/connect',
    component: Connect,
    thunk: async (dispatch) => {
      try {
        await dispatch(pagesActions.getPageFromCollection('page', 'connect'));
      } catch (err) {
        dispatch({ type: 'NOT_FOUND' });
      }
    }
  },
  CAMPAIGN_BUILDER_RESET: {
    path: '/create',
    thunk: async (dispatch, getState) => {
      const { campaignId } = getState().campaignBuilder;

      if (campaignId) {
        persistentState.set(config.localStorageCampaignIdKey, null);

        await dispatch(campaignBuilderActions.reset());
      } else {
        persistentState.set(config.localStorageBuilderKey, {
          step: 0,
          darkColoursToastDismissed: false
        });
      }

      dispatch({ type: 'CAMPAIGN_BUILDER' });
    }
  },
  CAMPAIGN_BUILDER_EDIT: {
    path: '/campaign/edit/:campaign_id',
    thunk: async (dispatch, getState) => {
      const { campaign_id } = getState().location.payload;
      const { step: queryStep } = getState().location.query ?? {};
      const { step: lsStep = 0 } = persistentState.get(
        config.localStorageBuilderKey
      );

      if (!campaign_id || isNaN(campaign_id)) {
        dispatch({ type: 'NOT_FOUND' });
      } else {
        const campaignId = Number(campaign_id);

        // User returning from campaign preview
        if (!queryStep && lsStep === 2) {
          return dispatch({ type: 'CAMPAIGN_BUILDER' });
        }

        // Admin editing a campaign OR user editing draft from dashboard
        await dispatch(campaignBuilderActions.reset());
        await dispatch(campaignBuilderActions.updateCampaignId(campaignId));

        persistentState.set(config.localStorageCampaignIdKey, 0);
        persistentState.set(config.localStorageCampaignIdKey, campaignId);

        return dispatch({ type: 'CAMPAIGN_BUILDER' });
      }
    }
  },
  CAMPAIGN_BUILDER_PRODUCT: {
    path: '/create/product/:sku?',
    thunk: async (dispatch) => {
      persistentState.set(config.localStorageBuilderKey, {
        step: 0,
        darkColoursToastDismissed: false
      });

      await dispatch(campaignBuilderActions.reset());

      dispatch({ type: 'CAMPAIGN_BUILDER' });
    }
  },
  CAMPAIGN_BUILDER: {
    path: '/create/campaign',
    component: CampaignBuilder,
    thunk: async (dispatch, getState) => {
      try {
        const { campaignId } = getState().campaignBuilder;

        // Old builder step 3 sets campaignId to NaN (string) after launch
        if (isNaN(campaignId)) {
          await dispatch(campaignBuilderActions.reset());
        }

        await dispatch(campaignBuilderActions.getCatalogueProducts());

        if (campaignId) {
          const campaignDispatch = dispatch(
            campaignBuilderActions.getCampaign()
          );
          const campaignProductsDispatch = dispatch(
            campaignBuilderActions.getCampaignProducts()
          );
          const artworkPositionsDispatch = dispatch(
            campaignBuilderActions.getArtworkPositions()
          );

          const dispatches = [
            await campaignDispatch,
            await campaignProductsDispatch,
            await artworkPositionsDispatch
          ];

          return await Promise.all(dispatches);
        }
      } catch (err) {
        // TODO: add a fallback product
      }
    }
  },
  CAMPAIGN_OR_STORE: {
    path: '/:slug_1?/:slug_2',
    component: Campaign,
    thunk: async (dispatch, getState, { extra: { baseApi } }) => {
      const { slug_2 } = getState().location.payload;
      let params = {
        url: slug_2
      };

      // If route is for a draft campaign
      if (getState().location.query && getState().location.query.key) {
        params.key = getState().location.query.key;
      }

      try {
        const response = await baseApi.get('/page-query', { params });

        if (response !== Object(response)) {
          dispatch({ type: 'SHOP' });
        }

        if (response.campaign) {
          dispatch({ type: 'CAMPAIGN_RECEIVED', payload: response.campaign });

          if (response.campaign.linkedCampaigns) {
            dispatch(campaignsActions.getLinkedCampaigns(slug_2));
          }

          if (getState().user.token) {
            await dispatch(userActions.setUserRoles());
          }

          if (
            response.campaign.user &&
            getState().user.userId === response.campaign.user.userId &&
            !hasExtendedRole(CREATOR, getState())
          ) {
            await dispatch(
              userActions.refreshToken(getState().user.refreshToken)
            );
          }

          if (hasExtendedRole(ADMIN, getState())) {
            await dispatch(
              campaignsActions.getAdminCampaign(response.campaign.campaignId)
            );
          }
        }

        if (response.store) {
          dispatch({ type: 'STORE_RECEIVED', payload: response.store });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: NOT_FOUND });
      }
    }
  }
  /**
   * Do not add any route here! Please add your new route before the last one.

   * The `CAMPAIGN_OR_STORE` must be the last one because it's a
   * "catch them all" route, as Everpress can have campaigns with any kind of slug.
   * ex: everpress.com/foo or everpress.com/bar/foo can be a campaign.
   */
};
