// Packages
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';
import { get, upperFirst, lowerCase } from 'lodash';
import universal from 'react-universal-component';

import Toggle from '../../../../molecules/Toggle';

import { InlineGrid } from '../../../Grid';
import { SelectNew } from '../../../Select';

import { DefaultError as Error } from '../../DefaultError';
import ScrollToError from '../../ScrollToError';
import TinySelect from '../../TinySelect';
import Checkbox from '../../Checkbox';
import TickCheckbox from '../../TickCheckbox';
import ButtonCheckbox from '../../ButtonCheckbox';
import ColourCircleCheckbox from '../../ColourCircleCheckbox';
import RadioBtn from '../../RadioBtn';
import TickRadioBtn from '../../TickRadioBtn';
import StrictNumber from '../../StrictNumber';

import WrapIf from '../../../../utils/WrapIf';

import generalStyles from './style/general.module.css';

// File uploader is async loaded because it's heavy and rarely used!
const FileInput = universal(() => import(`../../FileInput`));

class CustomField extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.containerRef = React.createRef();
    this.state = { isFocused: false };
  }

  onChange(e, onChange) {
    onChange && onChange(e);
    this.props.onCustomChange && this.props.onCustomChange(e.target);
  }

  renderComponent(props) {
    const {
      type,
      children,
      id,
      onCustomChange,
      field,
      setFieldValue,
      setFieldTouched,
      textCenter,
      noLabel,
      noBg,
      prefixedText,
      forwardedRef,
      ...domProps
    } = props;

    const InputComponent = type === 'textarea' ? 'textarea' : 'input';

    switch (type) {
      case 'select':
        return (
          <SelectNew
            id={id || field.name}
            onCustomChange={onCustomChange}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            {...field}
            {...domProps}
          />
        );
      case 'tinySelect':
        return (
          <TinySelect
            id={id || field.name}
            {...field}
            onChange={(value) => setFieldValue(field.name, value)}
            {...domProps}
          />
        );
      case 'radio':
        return (
          <RadioBtn
            id={id || field.name}
            checked={field.value === domProps.value}
            {...field}
            {...domProps}
          >
            {children}
          </RadioBtn>
        );
      case 'tickRadio':
        return (
          <TickRadioBtn
            id={id || field.name}
            checked={field.value === domProps.value}
            {...field}
            {...domProps}
          >
            {children}
          </TickRadioBtn>
        );
      case 'checkbox':
        return (
          <Checkbox {...field} {...domProps}>
            {children}
          </Checkbox>
        );
      case 'tickCheckbox':
        return (
          <TickCheckbox {...field} {...domProps}>
            {children}
          </TickCheckbox>
        );
      case 'buttonCheckbox':
        return (
          <ButtonCheckbox {...field} {...domProps}>
            {children}
          </ButtonCheckbox>
        );
      case 'colourCircleCheckbox':
        return (
          <ColourCircleCheckbox {...field} {...domProps}>
            {children}
          </ColourCircleCheckbox>
        );
      case 'toggle':
        return (
          <Toggle
            id={id || field.name}
            checked={field.value}
            {...field}
            onChange={(value) => setFieldValue(field.name, value)}
            {...domProps}
          />
        );
      case 'file':
        return (
          <FileInput
            id={id || field.name}
            {...field}
            onChange={(e) => this.onChange(e, field.onChange)}
            {...domProps}
            value={field.value || ''}
          />
        );
      case 'strictNumber':
        return (
          <StrictNumber
            className={classNames(generalStyles.input, {
              [generalStyles.inputValue]:
                !noLabel && !prefixedText && field.value !== null,
              [generalStyles.prefixedInput]: prefixedText && field.value,
              'md:text-center': textCenter
            })}
            id={id || field.name}
            {...field}
            value={field.value || field.min || 0}
            {...domProps}
          />
        );
      default:
        return (
          <InputComponent
            ref={forwardedRef}
            className={classNames(generalStyles.input, {
              [generalStyles.inputValue]:
                !noLabel && !prefixedText && field.value !== null,
              [generalStyles.prefixedInput]: prefixedText && (field.value || field.value === 0),
              [generalStyles.textarea]: type === 'textarea',
              [generalStyles.emptyInput]: (!field.value && field.value !== 0) || noLabel,
              [generalStyles.notEmptyInput]: (field.value || field.value === 0) && !noLabel,
              'md:text-center': textCenter
            })}
            type={type}
            id={id || field.name}
            {...field}
            value={field.value || (field.value === 0 ? 0 : '')}
            {...domProps}
          />
        );
    }
  }

  render() {
    const {
      name,
      onChange,
      onBlur,
      border,
      type,
      children,
      align,
      borderColor,
      noLabel,
      prefixedText,
      scrollToError,
      endAdornment,
      ...otherProps
    } = this.props;
    const selectHasNoLabel = type === 'select' && !children; // let's check if select has label next to it or not
    const noBorderType =
      selectHasNoLabel ||
      [
        'radio',
        'tickRadio',
        'checkbox',
        'tickCheckbox',
        'buttonCheckbox',
        'colourCircleCheckbox',
        'tinySelect'
      ].includes(type);

    return (
      <Field
        name={name}
        render={({
          field, // { name, value, onChange, onBlur }
          form: {
            touched,
            errors,
            setFieldTouched,
            setFieldValue,
            ...formikProps
          } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        }) => {
          field.onBlur = (e) => {
            onBlur?.(e);
            this.setState({ isFocused: false });
          };
          const onFocus = () => {
            this.setState({ isFocused: true });
          };
          const input = this.renderComponent({
            field,
            type,
            children,
            setFieldTouched,
            setFieldValue,
            onFocus,
            noLabel,
            prefixedText,
            ...otherProps
          });
          const isError = get(touched, name) && get(errors, name);
          const containerClassName = {
            [generalStyles.isInline]: type === 'tinySelect',
            [generalStyles.border]: noBorderType ? false : border,
            'border-blue-primary': this.state.isFocused,
            'border-grey-lighter': !this.state.isFocused || otherProps.disabled,
            'bg-grey-lightest': otherProps.disabled,
            'bg-white': !otherProps.disabled && !otherProps.noBg,
            'bg-transparent': !otherProps.disabled && otherProps.noBg,
            'text-grey-primary': otherProps.disabled,
            'Select-grey': borderColor === 'grey',
            [generalStyles.isNotValid]: isError,
            selectHasLabel: !selectHasNoLabel && type === 'select'
          };

          if (
            [
              'radio',
              'tickRadio',
              'checkbox',
              'tickCheckbox',
              'buttonCheckbox',
              'colourCircleCheckbox',
              'tinySelect'
            ].includes(type)
          ) {
            return (
              <div
                className={classNames(
                  generalStyles.container,
                  containerClassName
                )}
                ref={this.containerRef}
              >
                {scrollToError && (
                  <ScrollToError
                    {...formikProps}
                    errors={errors}
                    inputRef={this.containerRef}
                    name={name}
                  />
                )}
                <Fragment>
                  {type === 'tinySelect' ? (
                    input
                  ) : (
                    <InlineGrid justify="start">{input}</InlineGrid>
                  )}
                  {/* {isError && <Error>{get(errors, name)}</Error>} */}
                </Fragment>
              </div>
            );
          }
          if (type === 'select') {
            return (
              <Fragment>
                <div
                  className={classNames(
                    generalStyles.container,
                    generalStyles.selectContainer,
                    containerClassName
                  )}
                  ref={this.containerRef}
                >
                  {scrollToError && (
                    <ScrollToError
                      {...formikProps}
                      errors={errors}
                      inputRef={this.containerRef}
                      name={name}
                    />
                  )}
                  <div>
                    {!noLabel && field.value && (
                      <label
                        className={classNames(
                          generalStyles.label,
                          generalStyles.selectLabel,
                          {
                            'text-grey-primary': otherProps.disabled,
                            'text-grey-dark': !otherProps.disabled
                          }
                        )}
                      >
                        {otherProps.placeholder}
                      </label>
                    )}
                    {input}
                  </div>
                </div>
                {isError && (
                  <Error>{upperFirst(lowerCase(get(errors, name)))}</Error>
                )}
              </Fragment>
            );
          }

          return (
            <Fragment>
              <div
                className={classNames(
                  generalStyles.container,
                  containerClassName
                )}
                ref={this.containerRef}
              >
                {scrollToError && (
                  <ScrollToError
                    {...formikProps}
                    errors={errors}
                    inputRef={this.containerRef}
                    name={name}
                  />
                )}
                {!noLabel && (field.value || field.value === 0) && (
                  <label
                    className={classNames(generalStyles.label, {
                      'text-grey-primary': otherProps.disabled,
                      'text-grey-dark': !otherProps.disabled
                    })}
                  >
                    {otherProps.placeholder}
                    {type === 'textarea' && 'maxLength' in otherProps && (
                      <span className="text-grey-primary">
                        {' - '}
                        {otherProps.maxLength - (field.value.length || 0)}{' '}
                        characters left
                      </span>
                    )}
                  </label>
                )}
                <WrapIf
                  condition={!!endAdornment}
                  wrapper={(children) => (
                    <div>
                      {children}
                      <div className={generalStyles.endAdornment}>
                        {endAdornment}
                      </div>
                    </div>
                  )}
                >
                  {prefixedText && (field.value || field.value === 0) ? (
                    <div className={generalStyles.prefixedContainer}>
                      <div className={generalStyles.prefixedText}>
                        {prefixedText}
                      </div>
                      <div className="flex-1">{input}</div>
                    </div>
                  ) : (
                    input
                  )}
                </WrapIf>
              </div>
              {isError && (
                <Error>{upperFirst(lowerCase(get(errors, name)))}</Error>
              )}
            </Fragment>
          );
        }}
      />
    );
  }
}

CustomField.propTypes = {
  border: PropTypes.bool,
  textCenter: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
  options: PropTypes.array,
  align: PropTypes.string,
  onCustomChange: PropTypes.func,
  borderColor: PropTypes.oneOf(['black', 'grey']),
  prefixedText: PropTypes.string,
  scrollToError: PropTypes.bool
};

CustomField.defaultProps = {
  border: true,
  type: 'text',
  align: 'center',
  borderColor: 'black',
  scrollToError: true
};

export default CustomField;
